import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <main>
          {children}
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            declineButtonText="Decline"
            cookieName="google_analytics"
            enableDeclineButton={true}
            acceptOnScroll={true}
            acceptOnScrollPercentage={20}
            style={{
              textAlign: 'center',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
            }}
          >
            This website uses cookies to improve service, for analytical and
            advertising purposes. Please read our{' '}
            <a
              href={'/privacy'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Privacy Policy
            </a>{' '}
            &amp;{' '}
            <a
              href={'/termsAndConditions'}
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Terms and Conditions
            </a>
            . Confirm your consent to the use of cookies.
          </CookieConsent>
        </main>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
