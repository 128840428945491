import { createGlobalStyle } from 'styled-components';
import './flaticon.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #222831;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
      scroll-behavior: smooth;
  }

  .active{
    transform: height 100% !important;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /*
  .stack {
    position: relative;
    width: 100%;
    height: 600px;
  }
  .card {
    position: absolute;
    transform: translateY(0);
    animation: onTop 12s 2s ease 1;
  }
  .card.one {
    right: 0;
    top: 0;
    z-index: 2;
    animation-name: onTop;
  }
  .card.two {
    right: 10px;
    top: 10px;
    z-index: 1;
    animation-name: onMiddle;
  }
  .card.three {
    right: 20px;
    top: 20px;
    z-index: 0;
   animation-name: onBottom;
  }
  .card span {
    font-size: 24px;
    font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, "sans-serif";
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%,-50%);
  }
  @keyframes onTop {
    5% {
      transform: translateY(-300px);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    10% {
      transform: translateY(0);
      z-index: 0;
      right: 40px;
      top: 40px;
    }
    40% {
      transform: translateY(0);
      z-index: 0;
      right: 40px;
      top: 40px;
    }
    50% {
      transform: translateY(0);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    70% {
      transform: translateY(0);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    80% {
      transform: translateY(0);
      z-index: 2;
      right: 0;
      top: 0;
    }
  }
  @keyframes onMiddle {
    10% {
      transform: translateY(0);
      z-index: 2;
      right: 0;
      top: 0;
    }
    40% {
      transform: translateY(0);
      z-index: 2;
      right: 0;
      top: 0;
    }
    45% {
      transform: translateY(-300px);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    50% {
      transform: translateY(0);
      z-index: 0;
      right: 40px;
      top: 40px;
    }
    70% {
      transform: translateY(0);
      z-index: 0;
      right: 40px;
      top: 40px;
    }
    80% {
      transform: translateY(0);
      z-index: 1;
      right: 10px;
      top: 10px;
    }
  }
  @keyframes onBottom {
    10% {
      transform: translateY(0);
      z-index: 0;
      right: 20px;
      top: 20px;
    }
    40% {
      transform: translateY(0);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    50% {
      transform: translateY(0);
      z-index: 2;
      right: 0;
      top: 0;
    }
    70% {
      transform: translateY(0);
      z-index: 2;
      right: 0;
      top: 0;
    }
    75% {
      transform: translateY(-300px);
      z-index: 1;
      right: 20px;
      top: 20px;
    }
    80% {
      transform: translateY(0);
      z-index: 0;
      right: 20px;
      top: 20px;
    }
  }
  */

  .fadeInCustom{
    animation-fill-mode: both;
    animation-duration: 1000ms;
    animation-delay: 0ms;
    animation-iteration-count: 1;
    opacity: 1;
    animation-name: fadeInCustom;

    @keyframes fadeInCustom {
      0% {
       
    transform: translate3d(0px, 2%, 0px);

      }
      100% {
        transform: none;
      }
    }

  }

  .activeDot{
    
    width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  box-shadow: 0px 3px 8.46px 0.54px rgba(23, 65, 104, 0.2);
  z-index: 1;
  background: #fff;
  transform: translateY(-50%);

  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    &:after {
      width: 10px;
      height: 10px;
    }
  }

    &:after {
      background: #2962ff;
      content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.25s ease-in-out;
    }
  }

  .inactiveDot{
    width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  box-shadow: 0px 3px 8.46px 0.54px rgba(23, 65, 104, 0.2);
  z-index: 1;
  background: #fff;
  transform: translateY(-50%);

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ebedf5;
    transition: 0.25s ease-in-out;
  }
  @media (max-width: 480px) {
    width: 16px;
    height: 16px;
    &:after {
      width: 10px;
      height: 10px;
    }
  }
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    --mm-color-background-highlight: rgba(0, 0, 0, 0.05);
    --mm-color-background-emphasis: rgba(255, 255, 255, 0.4);
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #737a96;
    text-align: left;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }
`;
